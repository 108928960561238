.header-style {
  font-size: 36px;
  line-height: 43px;
  font-family: 'MarkPro-Black SKY';
}

.header-style-adv {
  font-size: 32px;
  line-height: 38px;
}

.error-header {
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;
  font-family: 'MarkPro-Black SKY';
}

.header-description {
  font-size: 16px;
  line-height: 20px;
}

.sky-app {
  flex-direction: column;
}

.line {
  width: 100%;
  background: #C5C5C5;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.responsive-image {
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .max-580 {
    max-width: 580px;
  }

  .container-style {
    margin-left: 60px;
    margin-right: 60px;
  }

}

@media (min-width: 1024px) {
  .header-style {
    font-size: 48px;
    line-height: 58px;
  }

  .header-style-adv {
    font-size: 32px;
    line-height: 43px;
  }

  .error-header {
    font-size: 56px;
    line-height: 60px;
  }

  .sky-app {
    flex-direction: row;

  }

  .container-style {
    margin-left: 120px;
    margin-right: 120px;
  }

  .line {
    width: 1px;
    border-right: 1px solid #C5C5C5;
    min-height: 800px;

  }
}

@media (min-width: 768px) {
  .header-description {
    font-size: 20px;
    line-height: 24px;
  }

}

@media (max-width: 768px) {
  .responsive-image.mobile {
    display: block;
    object-fit: contain;
    width: auto;
  }
}

@media (min-width: 769px) {
  .responsive-image.desktop {
    display: block;
  }
}

.banner-border {
  border-radius: 10px;
  border: 1px solid #C5C5C5;
}

@media (min-width: 768px) {
  .offer-banner-description {
    font-size: 16px;
    font-weight: 450;
    line-height: 20px;
    font-family: 'MarkPro-Book SKY'
  }
}

.upgrade-bg {
  background: rgba(243, 40, 54, 0.08);
}

.tippy-box {
  --bg-opacity: 1 !important;
  background-color: #0D1137 !important;
  background-color: rgba(0, 1, 58, var(--bg-opacity)) !important;
  padding: 0.9rem !important;
}

.tippy-box[data-placement^='top']>.tippy-arrow::before {
  border-top-color: #0D1137 !important;
  transform: scale(1.5);
}

.tippy-box[data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #0D1137 !important;
  transform: scale(1.5);
}

.tippy-box[data-placement^='left']>.tippy-arrow::before {
  border-left-color: #0D1137 !important;
  transform: scale(1.5);
}

.tippy-box[data-placement^='right']>.tippy-arrow::before {
  border-right-color: #0D1137 !important;
  transform: scale(1.5);
}

.confirmation-step-title {
  font-size: 28px;
  line-height: 32px;
}

.step-image img {
  max-height: 180px;
  max-width: 180px;
}

.step-content button {
  width: 280px;
}

.step-content-header {
  font-size: 36px;
  line-height: 43px;
}

.border-line {
  width: 60%;
  background: #C5C5C5;
  height: 1px;
  margin: 40px 20% 40px 20%;
}

.lg-view {
  display: inline-block;
}

.sm-view {
  display: none;
}

@media (max-width: 800px) {
  .lg-view {
    display: none;
  }

  .sm-view {
    display: inline-block;
  }

  .confirmation-step-title {
    font-size: 24px;
    line-height: 28px
  }

  .stepsumm-arrow svg {
    transform: rotate(90deg);
  }
  .step-image img {
    max-height: 100px;
    max-width: 100px;
  }

  .step-content button {
    width: 100%;
  }

  .step-content-header {
    font-size: 32px;
    line-height: 38px;
  }

  .border-line {
    width: 100%;
    background: #C5C5C5;
    height: 1px;
    margin: 40px 0px 40px 0px;
  }
}

.font-size-12px{
  font-size: 12px;
  font-family: 'MarkPro-Book SKY';
  line-height: 1.2;
}

.unorderedList {
  list-style-type: disc;
  padding-left: 1rem;
}

.unorderedList ul{
    list-style-type: circle;
    padding-left: 2rem;
  }

  .unorderedList ul ul{
    list-style-type: square;
    padding-left:3rem;
  }

  .unorderedList ul ul ul{
    list-style-type:disc;
    padding-left:4rem;
  }

  .orderedList {
    list-style-type: decimal;
    padding-left: 1rem;
}

   .orderedList ol {
    list-style-type: lower-alpha;
    padding-left: 2rem;
}


.orderedList ol ol {
    list-style-type: lower-roman;
    padding-left:3rem;
}

.orderedList ol ol ol {
    list-style-type: upper-alpha;
    padding-left:4rem;
}


.online-mobile-terms-orderedList{
    list-style-type: none;
    counter-reset: item;
  }

.online-mobile-terms-orderedList li {
    display: table;
    counter-increment: item;
  }

.online-mobile-terms-orderedList  li:before {
    content: counters(item, ".") '.';
    display: table-cell;
    padding-right: 0.2rem;
  }

.online-mobile-terms-orderedList ol ol li:before {
    content: counter(item, lower-alpha) ".";
    padding-left: 1rem;
  }

  .online-mobile-terms-orderedList ol ol ol li:before {
    content: counter(item, lower-roman) ".";
    padding-left: 1rem;
  }
  .min-height-container{
    min-height: 680px;
   }
   .min-height-compact{
     min-height: 300px;
    }
   @media (max-width:768px){
     .min-height-container{
       min-height: 553px;
      }
   }









.min-height-container{
 min-height: 680px;
}
.min-height-compact{
  min-height: 300px;
 }
@media (max-width:768px){
  .min-height-container{
    min-height: 553px;
   }
}