@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('/fonts/MarkPro-Black.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.md\:container {
  max-width: 1024px !important;
}
/* BB call support banner styles */

.fr-paragraph.fr-paragraph-text-size-s {
  font-size: 1.125rem;
}

.fr-paragraph.fr-paragraph-text-align-center {
  text-align: center;
}

.fr-paragraph.fr-paragraph-text-font-markprobold {
  font-family: 'MarkPro-Bold SKY';
}

.fr-paragraph.fr-paragraph-text-color-white {
  color: #fff;
}

.iframe-wrapper {
  width: 100%;
  aspect-ratio: 1023/575;
  overflow: hidden;
}
.promo-container {
  margin: 20px 60px 40px 40px;
  flex: 1;
}
@media (min-width: 1024px) {
  .promo-container {
    max-width: 1100px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .promo-container {
    max-width: 588px;
  }
}
@media (max-width: 767px) {
  .promo-container {
    max-width: 375px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.tvc-bundle-carousel .slick-list {
  padding-top: 30px;
  overflow-x: hidden !important;
}
@media (min-width: 767px) {
  .tvc-bundle-carousel {
    padding-left: 30px;
  }
}

.tvc-bundle-carousel .slick-arrow.slick-prev:before,
.tvc-bundle-carousel .slick-arrow.slick-next:before {
  content: none;
}
.tvc-bundle-carousel .slick-disabled {
  opacity: 0.1;
}
.tvc-bundle-carousel .slick-dots li {
  width: 10px;
}
.tvc-bundle-carousel .slick-track .slick-slide {
  width: inherit !important;
}

.tvc-bundle-carousel .slick-dots li.slick-active button:before {
  color: #0057ff;
  font-size: 20px;
  width: 10px;
  height: 22px;
  opacity: 1;
}
/* for BB banner text */
.banner_font_bold {
  font-size: 18px;
  font-family: 'MarkPro-Black SKY';
  line-height: 1.2;
}
.w-325px {
  width: 325px;
}
.w-920px {
  width: 920px;
}
.w-800px {
  width: 800px;
}
.margin-120{
  margin-left: 40px;
  margin-right: 40px;
}
@media (min-width: 1024px) {
  .margin-120{
  margin-left: 120px;
  margin-right: 120px;
}
}

.getsky-banner > div {
  padding: 12px 27px;
}
.getsky-banner-content p {
  color: white;
  font-size: 12px;
}
.getsky-banner-content p a {
  text-decoration: underline;
}
.getsky-banner-title {
  font-size: 22px;
}

@media (max-width: 768px) {
  .getsky-banner > div {
    padding: 28px 26px 21px 26px;
  }
  .getsky-banner-title {
    font-size: 28px;
  }
}
.banner-bg{
  background: linear-gradient(0deg, #00013A 43.68%, rgba(0, 1, 58, 0.00) 73.35%), url(<path-to-image>) lightgray -5.72px -11.618px / 102.667% 59.495% no-repeat;
  width:'375px';
  height:'364px'
}
